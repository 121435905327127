/* eslint-disable no-invalid-this */
import Storage from './Storage';

/**
 * This class facilitates the parameter synchronisation between
 * Cxense and KaChing.
 * The first script sets the parameter so the second script retrieves it.
 * The same logic needs to run in KaChing.
 */

class Sync {
  constructor(key, local, remote) {
    this._key = key;
    this._local = local;
    this._remote = remote;
    this._storage = 'sessionStorage';
  }

  get fetch() {
    return this._get() || this._set();
  }

  _get = () => {
    const str = Storage.get(this._key, this._storage);
    this._del();

    if (str) {
      const obj = JSON.parse(str);

      if (obj.source === this._remote) {
        return obj.value;
      }
    }
    return null;
  };

  _set = () => {
    const obj = {
      source: this._local,
      value: Sync._randomString(),
    };

    Storage.set(this._key, JSON.stringify(obj), this._storage);

    return obj.value;
  };

  _del = () => {
    Storage.del(this._key, this._storage);
  };

  static _randomString = () => {
    const result = [];
    let strLength = 16;
    const charSet = 'abcdefghijklmnopqrstuvwxyz0123456789';

    // eslint-disable-next-line no-plusplus
    while (strLength--) {
      result.push(charSet.charAt(Math.floor(Math.random() * charSet.length)));
    }

    return result.join('');
  };
}

export default Sync;
