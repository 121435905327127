import getCachedSegments from '../helpers/getCachedSegments';
import log from '../helpers/logger';

/**
 * handles string and objects containing SSP configuration
 * for either client or server user syncing
 */

class UserSync {
  constructor(ssps) {
    this.ssps = ssps;
  }

  static _getType = ssp => {
    let type;
    if (typeof ssp === 'string') {
      type = 's2s';
    } else if (typeof ssp === 'object' && ssp.type) {
      type = ssp.type;
    }

    return type;
  };

  sendRequests() {
    this.ssps.forEach(ssp => {
      if (UserSync._getType(ssp) === 's2s') {
        window.cX.callQueue.push(['sync', ssp]);
        log(`add usersync for ${ssp.partner ? ssp.partner : ssp} to queue`);
      } else if (ssp.queryId) {
        window.cX.callQueue.push([
          'invoke',
          () => {
            window.cX.getUserSegmentIds({
              persistedQueryId: ssp.queryId,
              callback: userSegmentIds => {
                UserSync._appNexusS2s(ssp.customerId, userSegmentIds);
              },
            });
          },
        ]);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (ssp.partner === 'appnexus') {
          UserSync._appNexusS2s(ssp.customerId, getCachedSegments());
        } else if (ssp.partner === 'facebook') {
          UserSync._facebook(ssp.customerId, getCachedSegments());
        } else {
          //
        }
      }
    });
  }

  static _appNexusS2s = (id, seg) => {
    if (seg.length > 0) {
      const el = document.createElement('img');
      const prefix = 'cxsegment_';
      const keyvalues = [];
      keyvalues.push(`add_Code=${prefix}${seg.join(`,${prefix}`)}`);
      keyvalues.push(`member=${id}`);
      el.setAttribute('src', `//ib.adnxs.com/seg?${keyvalues.join('&')}`);
      el.setAttribute('width', '1');
      el.setAttribute('height', '1');
      if (document.body) document.body.appendChild(el);
    }
  };

  static _facebook = (id, seg) => {
    if (typeof window.fbq === 'undefined') {
      return;
    }

    // fbq ignores long arrays
    const chunk = 80;
    let i = 0;

    for (i; i < seg.length; i += chunk) {
      window.fbq('trackSingleCustom', id, 'CxSegments', {
        segmentIds: seg.slice(i, i + chunk),
      });
    }
  };
}

export default UserSync;
