import Storage from './Storage';
import log from './logger';

const getCachedSegments = () => {
  let segmentIds = [];
  const segmentKey = PERSIST.segments[0];

  const rawSegmentIds = Storage.get(segmentKey.name, segmentKey.type);
  if (rawSegmentIds !== null) {
    segmentIds = JSON.parse(rawSegmentIds);
  } else {
    log('Segments not found in storage');
  }

  return segmentIds;
};

export default getCachedSegments;
