import createScript from './helpers/createScript';
import getValueFromObjectList from './helpers/getValueFromObjectList';
import getAllKeyValues from './helpers/getAllKeyValues';
import getLangIdFromUrl from './helpers/getLangIdFromUrl';
import getRegioFromCookie from './helpers/getRegioFromCookie';
import sortQueue from './helpers/sortQueue';
import trackImaEvents from './adapters/trackImaEvents';
import funnelEvents from './adapters/funnelEvents';
import intentEvents from './adapters/intentEvents';
import nativeArticleReads from './adapters/nativeArticleReads';
import Sync from './helpers/Sync';
import Storage from './helpers/Storage';
import UserSync from './adapters/UserSync';
import clean from './helpers/clean';
import kantar from './adapters/kantar';
import log from './helpers/logger';

const init = () => {
  const CX_URL = 'https://cdn.cxense.com/cx.js';
  const siteid = getLangIdFromUrl(CXENSE_SITE_ID);
  const extid = getValueFromObjectList(USER);
  const persist = PERSIST;
  let options = {};

  window.cX = window.cX || CXINIT;

  createScript(CX_URL);
  window.cX.shouldPoll = false;
  window.cX.callQueue = window.cX.callQueue || [];
  if (persist) {
    const s = new Sync('cxPrnd', 'cx', 'ga');
    window.cX.callQueue.push(['setRandomId', s.fetch]);
  }
  if (REGIO) {
    // regio box on popular MH titles
    const pc = getRegioFromCookie();
    if (pc) {
      window.cX.callQueue.push([
        'setUserProfileParameters',
        { regiobox: [pc] },
      ]);
    }
  }
  window.cX.callQueue.push(['setSiteId', siteid]);
  if (extid && extid.value) {
    const id = clean(extid.value);
    window.cX.callQueue.push(['addExternalId', { id, type: PREFIX }]);
    // used later when calling cX.getUserSegmentIds
    options = {
      identities: [
        {
          type: PREFIX,
          id,
        },
      ],
    };
  }

  if (KNT) {
    // NLprofiel
    if (KNT.path && KNT.path.test(document.location.pathname)) {
      kantar();
    }
  }

  if (CUSTOM) {
    // Collect additional datalayer parameters
    const cp = getAllKeyValues(CUSTOM);
    if (cp) {
      window.cX.callQueue.push(['setCustomParameters', cp]);
    }
  }

  if (URLARGS) {
    window.cX.callQueue.push([
      'invoke',
      () => {
        const urlArgs = window.cX.parseUrlArgs();
        const allowed = URLARGS;

        const cp = Object.keys(urlArgs)
          .filter(key => allowed.includes(key))
          .reduce((obj, key) => {
            obj[key] = urlArgs[key];
            return obj;
          }, {});

        window.cX.setCustomParameters(cp);
      },
    ]);
  }

  if (SHOP)
    // webshop events
    funnelEvents();

  if (INTENT)
    // intent events (Audience Interest Group)
    intentEvents();

  if (TRACKIMA)
    // video player events
    trackImaEvents();

  if (ARTICLE_READ)
    // native article reads
    nativeArticleReads();

  if (PERSIST) {
    if (SYNC) {
      if (!Storage.get(persist.cache.name, persist.cache.type)) {
        const u = new UserSync(SYNC);
        u.sendRequests();
      }
    }

    window.cX.callQueue.push([
      'invoke',
      () => {
        // register userid in localStorage
        const uid = window.cX.getUserId();
        Storage.set(persist.user.name, uid, persist.user.type);
        persist.segments.forEach(segment => {
          // register segmentids in localStorage
          try {
            window.cX.getUserSegmentIds(
              {
                persistedQueryId: segment.queryid,
                callback: userSegmentIds => {
                  Storage.set(
                    segment.name,
                    JSON.stringify(userSegmentIds),
                    segment.type
                  );
                },
              },
              options
            );
            // use cache flag
            Storage.set(persist.cache.name, 1, persist.cache.type);
          } catch (err) {
            log(`unable to get userSegmentIds from API`);
            log(err);
          }
        });
      },
    ]);
  }

  // Send Page View Event
  if (window.location.pathname.match(FILTER)) {
    window.cX.callQueue.push([
      'sendPageViewEvent',
      {
        location: window.location.href,
        referrer: document.referrer,
        useAutoRefreshCheck: false,
      },
    ]);
  }

  window.cX.callQueue = sortQueue(window.cX.callQueue);

  return null;
};

if (WAIT_FOR) {
  // reload for SPA
  document.addEventListener(WAIT_FOR, init);
  log(`waiting for ${WAIT_FOR} to initialize`);
  // tell referer async loading is ready
  document.dispatchEvent(new CustomEvent('cxLoaded'));
  log(`dispatch cxLoaded event`);
} else {
  init();
}
